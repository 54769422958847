import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import { AngularFireStorage } from '@angular/fire/storage';

@Injectable({
  providedIn: 'root'
})
export class FirestoreService {

  constructor(private firestore: AngularFirestore
    ) { }

    //Crea un nuevo testimonio
    public createTestimony(url: string) {
      return this.firestore.collection('testimony').add({url: url});
    }

    public getAllTestimonies() {
      return this.firestore.collection('testimony').snapshotChanges();
    }
}