import { Component, OnInit } from '@angular/core';
import { FirestoreService } from 'src/app/services/firestore/firestore.service';

@Component({
  selector: 'app-testimonies',
  templateUrl: './testimonies.component.html',
  styleUrls: ['./testimonies.component.scss']
})
export class TestimoniesComponent implements OnInit {

  testimonies: any[];
  constructor(private firestoreService: FirestoreService) { 
    this.testimonies = [];
  }

  ngOnInit() {
    this.firestoreService.getAllTestimonies().subscribe((testimoniesSnapshot) => {
      console.log('datas', testimoniesSnapshot)
      testimoniesSnapshot.forEach((data: any) => {
        this.testimonies.push(data.payload.doc.data())
        console.log('data', data.payload.doc.data())
        // this.testimonies.push({
        //   text: data.payload.doc.id,
        //   dataTestimonie: data.payload.doc.data()
        // });
      })
    });
  }

}
