import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './modules/home/home.component';
import { LoginComponent } from './modules/login/login.component';
import { SecureInnerPagesGuard } from './auth/secure-inner-pages.guard';
import { EditTestimonyComponent } from './modules/edit-testimony/edit-testimony.component';
import { AuthGuard } from './auth/auth.guard';
import { TestimoniesComponent } from './modules/testimonies/testimonies.component';


const routes: Routes = [
  { path: '', component: HomeComponent },
  // { path:'testimonies', component: TestimoniesComponent },
  // { path: 'login', component: LoginComponent, canActivate: [SecureInnerPagesGuard]},
  // { path: 'edit-testimony', component: EditTestimonyComponent, canActivate: [AuthGuard] },

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
