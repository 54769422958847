import { Component, OnInit, Output, HostBinding, HostListener } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { FirestoreService } from '../../services/firestore/firestore.service';
import { FirebaseStorageService } from 'src/app/services/firestore/firebase-storage.service';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  constructor (
    private firebaseStorage: FirebaseStorageService, private firestoreService: FirestoreService
  ) {

  }

  public archivoForm = new FormGroup({
    archivo: new FormControl(null, Validators.required),
  });
  public mensajeArchivo = 'No hay un archivo seleccionado';
  public datosFormulario = new FormData();
  public nombreArchivo = '';
  public URLPublica = '';
  public porcentaje = 0;
  public finalizado = false;
  public filesInArray: any[] = [];

  ngOnInit(){}


  //Evento que se gatilla cuando el input de tipo archivo cambia
  public cambioArchivo(event) {
    if (event.target.files.length > 0) {
      for (let i = 0; i < event.target.files.length; i++) {
        this.mensajeArchivo = `Archivo preparado: ${event.target.files[i].name}`;
        this.filesInArray.push(event.target.files[i].name)
        this.nombreArchivo = event.target.files[i].name;
        this.datosFormulario.delete('archivo');
        this.datosFormulario.append('archivo', event.target.files[i], event.target.files[i].name)
      }
    } else {
      this.mensajeArchivo = 'No hay un archivo seleccionado';
    }
  }

  byDrop(event) {
    for (let i = 0; i < event.length; i++) {
      this.mensajeArchivo = `Archivo preparado: ${event[i].name}`;
      this.filesInArray.push(event[i].name)
      this.nombreArchivo = event[i].name;
      this.datosFormulario.delete('archivo');
      this.datosFormulario.append('archivo', event[i], event[i].name)
    }
  }

  //Sube el archivo a Cloud Storage
  async subirArchivo() {
    let archivo = this.datosFormulario.get('archivo');
    let tarea = this.firebaseStorage.tareaCloudStorage(this.nombreArchivo, archivo);
    let referencia = await this.firebaseStorage.referenciaCloudStorage(this.nombreArchivo);

    //Cambia el porcentaje
    await tarea.percentageChanges().subscribe((porcentaje) => {
      this.porcentaje = Math.round(porcentaje);
      if (this.porcentaje == 100) {
        this.finalizado = true;
      }
    });
    await this.getUrl(referencia)
    
  }
  
  async getUrl(referencia) {
    await referencia.getDownloadURL().subscribe((URL) => {
        this.URLPublica = URL;
        this.createTestimony(URL);
    });
  }

  deleteAttachment(index) {
    this.filesInArray.splice(index, 1)
  }

    createTestimony(text: string) {
    this.firestoreService.createTestimony(text).then(() => {
      this.filesInArray = [];
      console.log('Documento creado exitósamente!');
    }, (error) => {
      console.error(error);
    });
  }
}


