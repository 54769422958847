import { BrowserModule } from '@angular/platform-browser';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { DragdropModule } from './directives/dragdrop.module';
import { EditTestimonyComponent } from './modules/edit-testimony/edit-testimony.component';
import { TestimoniesComponent } from './modules/testimonies/testimonies.component';
import { environment } from '../environments/environment';


// Auth service
import { AuthService } from './services/user/auth.service';

// Import canActivate guards
import { AuthGuard } from './auth/auth.guard';
import { SecureInnerPagesGuard } from './auth/secure-inner-pages.guard';

import { HomeComponent } from './modules/home/home.component';
import { LoginComponent } from './modules/login/login.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

// //Librerías 
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {MatButtonModule} from '@angular/material/button';
import {MatGridListModule} from '@angular/material/grid-list';
import {MatExpansionModule} from '@angular/material/expansion';
import { MatFileUploadModule } from 'angular-material-fileupload';
import {MatProgressBarModule} from '@angular/material/progress-bar';


// //Firebase
import { AngularFireModule } from '@angular/fire';
import { AngularFirestore } from '@angular/fire/firestore';
import { NgxAuthFirebaseUIModule } from 'ngx-auth-firebaseui';
import { AngularFireStorageModule } from '@angular/fire/storage';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    LoginComponent,
    EditTestimonyComponent,
    TestimoniesComponent,
    
  ],
  imports: [
    BrowserModule,
    DragdropModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatGridListModule,
    MatExpansionModule,
    MatFileUploadModule,
    MatProgressBarModule,
    FormsModule,
    ReactiveFormsModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireStorageModule,
    NgxAuthFirebaseUIModule.forRoot({
      apiKey: 'AIzaSyDu-N6QXJV6LVrLp7xo6AstEJpRzUUUcC0',
      authDomain: 'juntas-hacemos-historia.firebaseapp.com',
      databaseURL: 'https://juntas-hacemos-historia.firebaseio.com',
      projectId: 'juntas-hacemos-historia',
      storageBucket: 'juntas-hacemos-historia.appspot.com',
      messagingSenderId: '63078070854'
    }),
    
  ],
  schemas:[CUSTOM_ELEMENTS_SCHEMA],
  providers: [AngularFirestore, AuthService, AuthGuard, SecureInnerPagesGuard],
  bootstrap: [AppComponent]
})
export class AppModule { }
