import { Component, OnInit } from '@angular/core';
import {AuthProvider} from 'ngx-auth-firebaseui';
import { AuthService } from 'src/app/services/user/auth.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  constructor(private authService: AuthService ) { }

  providers = AuthProvider;

  ngOnInit() {
  }

  printUser(event) {
    console.log(event);
    this.authService.verifyIfIsFran()
  }

  printError(event) {
   console.error(event);
  }

}
